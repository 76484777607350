<template>
  <div class="card">
    <h3 class="card-title">Listar personas</h3>
    <div v-if="personas">
      <table-list
        :showFilter="true"
        :data="personas"
        :header="tableListHeader"
        :body="tableListBody"
        :actionButton="actionButton"
        @rowClicked="showPersona"
      ></table-list>
    </div>
  </div>
</template>
<script>
import { PersonasService } from "../services/PersonasServices";
import TableList from "@/components/elements/TableList";
export default {
  name: "ListarPersonas",
  components: {
    TableList,
  },
  data() {
    return {
      personas: null,
      tableListBody: PersonasService.body(),
      tableListHeader: PersonasService.headers(),
      actionButton: {
        text: "Nueva persona",
        action: () => {
          this.$router.push({name: 'NuevaPersona'})
        },
      },
    };
  },
  mounted() {
    PersonasService.api.getAll().then((personas) => (this.personas = personas));
  },
  methods: {
    showPersona(persona) {
      this.$router.push({name:'VerPersona', params:{id: persona.id}})
    },
  },
};
</script>